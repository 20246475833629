// Fonts 
@import url('https://fonts.googleapis.com/css?family=Exo:500,600,700|Roboto&display=swap');
html{
  overflow-x: hidden;
}

body {
  line-height: 1.6;
  font-family: $secondary-font;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  color: $base-color;
  font-weight: 400;
  overflow-x: hidden;

}
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  font-family:$primary-font ;
  font-weight:700;
  color:$black;
}

h1 ,.h1{
  font-size: 2.5rem;
  
}

h2,.h2 {
  font-size: 44px;
}

h3,.h3 {
  font-size: 1.5rem;
}

h4,.h4 {
  font-size: 1.3rem;
  line-height: 30px;
}

h5,.h5 {
  font-size: 1.25rem;
}

h6,.h6 {
  font-size: 1rem;
}


p{
  line-height: 30px;
}